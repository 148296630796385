import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Notification from "../../../components/notifications";

export default {
  name: "RecapitulationTeller",
  data() {
    return {
      property: {
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
        listElement: {
          recapitulationTeller: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        userId: "",
        dateFrom: "",
        dateTo: "",
        userName: "",
        totalTransaction: 0,
        total: 0,
        officeName: "",
        exportContent: "",
        exportToExcel: {
          header: [],
          data: [],
        },
      },
      table: {
        header: {
          recapitulationTeller: [
            {
              key: "officeName",
              label: "Cabang",
            },
            {
              key: "transactionDate",
              label: "Tanggal",
            },
            {
              key: "transactionCode",
              label: "Kode Transaksi",
            },
            {
              key: "countTransaction",
              label: "Keterangan Nilai",
            },
            {
              key: "nominal",
              label: "Nominal",
            },
          ],
        },
        data: {
          recapitulationTeller: [],
        },
      },
      options: {
        exportOptions: [],
        user: [],
      },
    };
  },
  methods: {
    handleChangeUser() {
      this.dataForm.exportContent = "";
      this.dataForm.exportToExcel.header = [];
      this.dataForm.exportToExcel.data = [];
      this.dataForm.total = 0;
      this.dataForm.dateFrom = "";
      this.dataForm.dateTo = "";
      this.options.user.map((i) => {
        if (i.value === this.dataForm.userId) {
          this.dataForm.officeName = i.officeName;
          this.dataForm.userName = i.text;
          this.helperGetRecapitulationTellerDataTable();
        }
      });
    },
    handleCatchErrorRecapitulationTeller(error) {
      console.log(error.response);
      this.table.data.recapitulationTeller = [];
      this.property.listElement.recapitulationTeller.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async helperGetRecapitulationTellerDataTable() {
      this.table.data.recapitulationTeller = [];
      this.property.listElement.recapitulationTeller.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/recapitulation-teller",
          payload: {
            userId: this.dataForm.userId,
            dateFrom: this.dataForm.dateFrom,
            dateTo: this.dataForm.dateTo,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.recapitulationTeller = resp.data.data.detail;
            this.dataForm.total = resp.data.data.total;
          } else {
            this.property.listElement.recapitulationTeller.message =
              resp.data.message;
            this.dataForm.total = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorRecapitulationTeller(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.recapitulationTeller.downloading = false;
        }, timeout);
      }
    },
    async handleChangeDateFrom(event) {
      this.table.data.recapitulationTeller = [];
      this.dataForm.exportContent = "";
      this.dataForm.exportToExcel.header = [];
      this.dataForm.exportToExcel.data = [];
      this.property.listElement.recapitulationTeller.downloading = true;
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/recapitulation-teller",
            payload: {
              userId: this.dataForm.userId,
              dateFrom: event,
              dateTo: this.dataForm.dateTo,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.recapitulationTeller = resp.data.data.detail;
              this.dataForm.total = resp.data.data.total;
            } else {
              this.property.listElement.recapitulationTeller.message =
                resp.data.message;
              this.dataForm.total = 0;
            }
          }, timeout);
        } catch (error) {
          setTimeout(() => {
            this.handleCatchErrorRecapitulationTeller(error);
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.recapitulationTeller.downloading = false;
          }, timeout);
        }
      }
    },
    async handleChangeDateToRecapitulationTeller(event) {
      this.dataForm.exportContent = "";
      this.dataForm.exportToExcel.header = [];
      this.dataForm.exportToExcel.data = [];
      this.table.data.recapitulationTeller = [];
      this.property.listElement.recapitulationTeller.downloading = true;
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/recapitulation-teller",
            payload: {
              userId: this.dataForm.userId,
              dateFrom: this.dataForm.dateFrom,
              dateTo: event,
            },
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.recapitulationTeller = resp.data.data.detail;
              this.dataForm.total = resp.data.data.total;
            } else {
              this.property.listElement.recapitulationTeller.message =
                resp.data.message;
              this.dataForm.total = 0;
            }
          }, timeout);
        } catch (error) {
          setTimeout(() => {
            this.handleCatchErrorRecapitulationTeller(error);
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.recapitulationTeller.downloading = false;
          }, timeout);
        }
      }
    },
    async getUserTellers() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "user/tellers",
          endPoint: "parameter",
          payload: {
            isIncluded: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.userId;
            const text = i.userName;
            const officeName = i.officeName;
            this.options.user.push({ text, value, officeName });
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    handleDefaultMessageTable() {
      this.property.listElement.recapitulationTeller.message =
        "PIlih User Terlebih Dahulu";
    },
    handleChargingOptionsComponent() {
      this.options.user.push({ value: "", text: "-- Pilih --" });
      this.options.exportOptions.push(
        { value: "", text: "-- Pilih --" },
        { value: "PDF", text: "Pdf" },
        { value: "EXCEL", text: "Excel" }
      );
    },
    async handleChangePilihEksport() {
      this.dataForm.exportToExcel.data = [];
      this.dataForm.exportToExcel.header = [];
      if (this.dataForm.exportContent === "EXCEL") {
        this.table.header.recapitulationTeller.map((index) => {
          const field = index.key;
          const label = `${index.label}`;
          this.dataForm.exportToExcel.header.push({ field, label });
        });
        await this.table.data.recapitulationTeller.map((index) => {
          const officeName = index.officeName;
          const transactionDate = index.transactionDate;
          const transactionCode = `(${index.transactionCode}) - ${index.transactionName}`;
          const countTransaction = index.countTransaction;
          const nominal = `${
            index.nominal
              ? index.nominal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`;
          this.dataForm.exportToExcel.data.push({
            officeName,
            transactionDate,
            transactionCode,
            countTransaction,
            nominal,
          });
        });
      } else {
        this.dataForm.exportToExcel.data = [];
        this.dataForm.exportToExcel.header = [];
      }
    },
    handlePrintRecapitulationTeller() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const headerFinal = [];
      const header = this.table.header.recapitulationTeller.map((index) => {
        headerFinal.push(index.label);
      });
      const finalDataTable = [];
      var numbering = 1;
      const loopDataTable = this.table.data.recapitulationTeller.map(
        (index) => {
          finalDataTable.push([
            index.officeName,
            `${
              index.transactionDate
                ? new Date(index.transactionDate).getDate()
                : ""
            } ${
              index.transactionDate
                ? this.property.accessories.day[
                    new Date(index.transactionDate).getMonth()
                  ]
                : ""
            } ${
              index.transactionDate
                ? new Date(index.transactionDate).getFullYear()
                : ""
            }`,

            `(${index.transactionCode})-${index.transactionName}`,
            index.countTransaction,
            `${
              index.nominal
                ? index.nominal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
                : 0
            }`,
          ]);
        }
      );
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc
        .setFontSize(10)
        .text(
          `Laporan Rekapitulasi Transaksi Harian `,
          pageWidth / 2 - 1.1,
          0.5
        );
      doc
        .setFontSize(8)
        .text(`User          :  ${this.dataForm.userName}`, 0.55, 1.0);
      doc
        .setFontSize(8)
        .text(
          `Tanggal     :  ${this.dataForm.dateFrom} s/d ${this.dataForm.dateTo}`,
          0.55,
          1.2
        );
      doc
        .setFontSize(8)
        .text(`Cabang     :   ${this.dataForm.officeName}`, 0.55, 1.4);
      doc
        .setFontSize(8)
        .text(
          `Total          :  ${
            this.dataForm.total
              ? this.dataForm.total
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          0.55,
          1.6
        );

      doc.autoTable({
        columnStyles: {
          0: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
        },
        headStyles: {
          halign: "center",
          fontSize: 6.4,
          fontStyle: "bold",
          font: "normal",
        },
        head: [headerFinal],
        theme: "striped",
        body: finalDataTable,
        margin: { top: 2.0 },
        halign: "center",
        didDrawPage: function(data) {
          data.settings.margin.top = 0.9;
        },
      });
      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      doc.setFontSize(9).text("( Maker )", 0.55, pageHeight - 1);
      doc.setFontSize(9).text("( Taker )", 1.2, pageHeight - 1);
      doc.setFontSize(9).text("( Signer )", 1.82, pageHeight - 1);
      doc
        .setFontSize(9)
        .text(
          `Print By  :  ${
            JSON.parse(
              this.decryptBASE64(
                sessionStorage.getItem("@vue-session/auth-bundle")
              )
            ).userName
          } ( ${
            JSON.parse(
              this.decryptBASE64(
                sessionStorage.getItem("@vue-session/auth-bundle")
              )
            ).userCode
          } )`,
          0.55,
          pageHeight - 0.5
        );
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
  },
  mounted() {
    this.handleChargingOptionsComponent();
    this.handleDefaultMessageTable();
    this.getUserTellers();
  },
};
